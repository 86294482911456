import React from 'react';
import { Layout } from "layouts/layout";
import { graphql, HeadFC, HeadProps, PageProps } from "gatsby";
import { SectionHeader } from "components/layout/section-header";

import { Service as ServiceSchema } from "models/schemas/service";
import { Box, Container, Grid } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { ProcessCard } from "content/cards/process-card";
import { markdownOptions } from "components/options/markdown-options";
import { AssetBanner } from "content/banners/asset-banner";
import { PageMetadata } from "components/layout/page-metadata";

export type ServiceProps = {
    service: ServiceSchema;
}

const Service: React.FC<PageProps<ServiceProps>> = ({ data }) => {

    const { service } = data;

    return (
        <Layout>
            <Container maxWidth={'lg'}>
                {service.page.banner &&
                    <AssetBanner asset={service.page.banner}/>
                }
                <Grid container spacing={10} justifyContent={"center"}>
                    <Grid item container>
                        <SectionHeader
                            title={service.page.link.label}
                            gutterTop
                        />
                    </Grid>
                    <Grid item md={10}>
                        <Markdown options={markdownOptions}>
                            {service.introduction.markdown}
                        </Markdown>
                    </Grid>
                    <Grid item container spacing={4} position={"relative"} justifyContent={"center"}>
                        <Box className={"radial-gradient"}/>
                        {service.processes.map((process, index) => (
                            <Grid item md={6} lg={4} key={process.title}>
                                <ProcessCard process={{
                                    ...process,
                                    title: `${index + 1}. ${process.title}`
                                }} hideDescription/>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item md={10}>
                        <Markdown options={markdownOptions}>
                            {service.furthermore.markdown}
                        </Markdown>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}
export default Service;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.service.page.metadata}/>
)

export const query = graphql`
    query($id: String!) {
        service: graphCmsService(id:  { eq: $id }) {
            summary
            introduction {
                markdown
            }
            processes {
              title
              description
              items
            }
            furthermore {
                markdown
            }
            page {
                link {
                    label
                    url
                }
                metadata {
                    title
                    description
                    thumbnail {
                      url
                    }
                    embedImage {
                      url
                    }
                    jsonLD
                }
                banner{
                    url
                    alt
                }
            }
        } 
    }`